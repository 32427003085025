.textInputLabel {
  color: #000000 !important;
  font-size: 14.5px !important;
  font-family: GilroySemiBold !important;
}

.deleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red !important;
  width: 120px;
  height: 45px;
  border-radius: 8px !important;
  padding: 0 18px;
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  cursor: pointer;
}

.errorText {
  color: #cd0000;
  font-size: 10px !important;
  font-family: GilroyMedium !important;
}

.group--categories {
  display: flex;
  flex-direction: row;
}

.categoryBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid !important;
  height: 48px;
  border-radius: 2px !important;
  padding: 0 18px;
  background-color: #ffffff;
  color: #000 !important;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  cursor: pointer;
}